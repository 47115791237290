<template lang="html">
  <div class="social">
    <a href="#"><img :src="'/img/'+icons.facebook" class="social-icon icon-facebook"/></a>
    <a href="#"><img :src="'/img/'+icons.twiter" class="social-icon icon-twiter"/></a>
    <a href="#"><img :src="'/img/'+icons.instagram" class="social-icon icon-instagram"/></a>
  </div>
</template>

<script>
export default {
  name:'Social',
  data(){
    return {
      category : this.$route.params.category,
      icons:{
        facebook:'20.png',
        twiter : '21.png',
        instagram:'22.png'
      }
    };
  },
  methods: {
    animate() {
      this.$anime.timeline({
        duration:3000,
      }).add({
        targets:'.social .icon-facebook',
        opacity:0.7,
        duration:1000,
      }).add({
        targets:'.social .icon-twiter',
        opacity:0.7,
        duration:1000,
      }).add({
        targets:'.social .icon-instagram',
        opacity:0.7,
        duration:1000,
      })
    }
  },
  mounted() {
    //do something after mounting vue instance
    if (this.category == 'plast') {
      this.icons.facebook = '14.png';
      this.icons.twiter = '15.png';
      this.icons.instagram = '8.png';
    }else if(this.category == 'building'){
      this.icons.facebook = 'facebooksazeh.png';
      this.icons.twiter = 'twitersazeh.png';
      this.icons.instagram = 'instagramsazeh.png';
    }

  },
  created() {
    //do something after creating vue instance
    setTimeout(() => {
      this.animate();
    },6000);
  }
}
</script>

<style lang="css" scoped>
.social{
  position: absolute;
  width: 10%;
  z-index: 500;
  bottom :2em;
  left: 30px;
  direction: ltr;
}
.social-icon{
  width: 1.7em;
  display: block;
  z-index: 200;
  padding-top: 20px;
  opacity: 0;
}
.icon-facebook{
  width: 1.4em;
}
.social-icon:hover{
  opacity: 1;
}
</style>
