<template>
  <div class="slider w-100 h-100">
    <div class="content w-100 h-100">
      <div class="logo">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 119"><defs></defs><title>1</title>
          <path class="cls-1" d="M105.9,43.5H106c20.27-.27,35.72,16,35.72,35.72A35.74,35.74,0,0,1,106,114.94h-.47a93.44,93.44,0,0,0,9.59.91,50.6,50.6,0,0,0,43.38-24.52,38.9,38.9,0,0,0-32.78-59.86,38.57,38.57,0,0,0-29.32,13.1,35.46,35.46,0,0,1,9.45-1.06Z"/>
          <path class="cls-2" d="M106,48.13c-9.11,0-16.59,3.4-23,10.18,0,0-25.91,26.12-27.3,27.36l-.85.71A35.87,35.87,0,0,1,24,93.76c-1-.23-2-.49-2.92-.79.34.88.72,1.74,1.12,2.59A36,36,0,0,0,32.71,108.5,35.8,35.8,0,0,0,79.19,106c.34-.33,24-22.78,35.09-33a33.24,33.24,0,0,1,6.22-4.53,29.82,29.82,0,0,1,12.85-3.9A31,31,0,0,0,106,48.13Z"/>
          <path class="cls-3" d="M66.05,52.24c-6.55-3.69-14.33-5.15-22.34-5.15A51.27,51.27,0,0,0,1.59,69.22c0,.11-.05.21-.06.31.88-.48,1.79-.92,2.72-1.32a35.41,35.41,0,0,1,7.65-2.36c8.56-1.62,16.09.84,23.15,4.75l1,.55c.68.41,7.89,5,16.76,10.66L76,58.61C72.34,56.27,69.06,53.93,66.05,52.24Z"/><path class="cls-3" d="M122.24,87a33.79,33.79,0,0,1-7-3.3L112,81.63,87.86,104.36a38.15,38.15,0,0,0,6.6,3.69h0a29.53,29.53,0,0,0,17.28,1.65,31,31,0,0,0,23.85-21.26A30,30,0,0,1,122.24,87Z"/>
          <path class="cls-4" d="M29.58,20.87s6.7,24.78,34.49,22.74c20.86-1.45,30.06-7.36,45.48-14.44,12.69-5.18,45.82-7.84,56,23.41C161.18,23.06,139,5.22,111.88,4.38c-20.5-.73-32.72,10.48-46.8,15.86C50.88,25.89,38.06,25.26,29.58,20.87Z"/></svg>
      </div>
      <social></social>
      <div class="slogon d-flex justify-content-center align-items-center">
        <div class="col-6 o-hidden">
          <transition name="fade">
            <img :src="'/img/logos/'+$route.params.language+'/'+imgSrc" v-if="show" class="slogon-text" alt="">
          </transition>
        </div>
      </div>
      <div class="d-flex justify-content-center h-100 b-black">
        <div class="categories col-12 bordered w-100 h-100">
          <transition-group name="taj" tag="div">
            <div class="thumbnail w-100 h-100" v-for="i in [currentIndex]" :key="i">
              <div class="thumbnail-shadow"></div>
              <img class="thumbnail-img h-100" id="thumbnail-1" refs="flip" :src="currentImg" />
            </div>
            
          </transition-group>
          <div class="d-flex justify-content-center align-items-center slider-navigation">
            <div class="slider-nav" v-for="(img,key) in images" :key="key" v-on:click="changeSlide(key)">
                  <div class="slider-nav-img active" v-if="key == currentInd"></div>
                  <div class="slider-nav-img" v-else></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Social from './Social'
export default {
  name:'Slider',
  data(){
    return {
      imgSrc:'',
      show:true,
      categoryImages:{
        tajhiz:[
        "/img/banner/tajhiz-1.jpg",
        "/img/banner/tajhiz-2.jpg",
      ],
      plast:[
        "/img/banner/plast-2.jpg",
        "/img/banner/plast-1.jpg",
        "/img/banner/plast-3.jpg",
      ],
      building:[
        "/img/banner/sakhtemani-1.jpg",
        "/img/banner/sakhtemani-2.jpg",
        "/img/banner/sakhtemani-3.jpg",
      ]
      },
      images: null,
      timer: null,
      currentIndex: 0,
      imageIndex:0,
    };
  },
  components: {
    Social,
  },
  methods: {
    slogonShow(){
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
      }).add({
        targets :'.slogon-text',
        duration:2000,
        translateX:'-110%',
      });
    },
    async startSlide() {
      //await setTimeout(console.log('done'),15000);
      this.timer = setInterval(this.next, 8000)
    },
    changeSlide(key){
      this.currentIndex = key;
    },
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    async slogonHide(img){
      await this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
        complete:function(anim){

        }
      }).add({
        targets :'.slogon-text',
        duration:2000,
        translateX:'-110%',
      });
    },
    logoAnimation(){
      this.$anime.timeline({
        duration:10100,
        delay:function(el, i) { return i * 250 },
        //loop:true,
      }).add({
        targets: '.logo .cls-3,.logo .cls-2,.logo .cls-4,.logo .cls-1',
        strokeDashoffset: [this.$anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 1000,
        delay: function(el, i) { return i * 250 },
        direction: 'alternate',
        //loop: true
      }).add({
        targets:'.logo .cls-2',
        easing: 'easeInOutSine',
        duration: 700,
        fill:'#f9b729',
        delay: function(el, i) { return i * 250 },
        direction: 'alternate',
      }).add({
        targets:'.logo .cls-3',
        easing: 'easeInOutSine',
        duration: 700,
        fill:'#e44135',
        delay: function(el, i) { return i * 250 },
        direction: 'alternate',
      }).add({
        targets:'.logo .cls-4,.logo .cls-1',
        easing: 'easeInOutSine',
        duration: 700,
        fill:'#0c7da6',
        delay: function(el, i) { return i * 250 },
        direction: 'alternate',
      });
    },
  },
  created() {
    setTimeout(() => {
      this.slogonShow();
      this.logoAnimation();
    }, 7000);
    if (this.$route.params.category == 'tajhiz') {
      this.imgSrc = 'azarpajooheshTajhiz.png';
      this.images = this.categoryImages['tajhiz'];
    }else if(this.$route.params.category == 'plast'){
      this.imgSrc = 'azarpajooheshPlast.png';
      this.images = this.categoryImages['plast'];
    }else if(this.$route.params.category == 'building'){
      this.imgSrc = 'azarpajooheshBuilding.png';
      this.images = this.categoryImages['building'];
    }
    //setTimeout(this.startSlide(),1000000);
  },
  mounted() {
    if (this.$route.params.category == 'tajhiz') {
      this.imgSrc = 'azarpajooheshTajhiz.png';
      this.images = this.categoryImages['tajhiz'];
    }else if(this.$route.params.category == 'plast'){
      this.imgSrc = 'azarpajooheshPlast.png';
      this.images = this.categoryImages['plast'];
    }else if(this.$route.params.category == 'building'){
      this.imgSrc = 'azarpajooheshBuilding.png';
      this.images = this.categoryImages['building'];
    }
    //this.startSlide();
    setTimeout(()=>{
      this.startSlide();
    },7000)
  },
  computed:{
    currentImg: function() {
     //this.imageIndex = Math.abs(this.currentIndex) % this.images.length;
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
    currentInd:function(){
      return Math.abs(this.currentIndex) % this.images.length
    }
  }
}
</script>

<style lang="css">
.slider{
  background: #000;
  position: absolute;
  z-index: 100;
}
.slogon{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 400;
}
.slogon-text{
  width: 100%;
  position: relative;
  left: 110%;
}
.logo{
  position: absolute;
  left: 46%;
  top: 50px;
  width: 8%;
  z-index: 400;
}
.logo img{
  width: 100%;
}
.bordered{
  border: 1px solid black;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  filter: grayscale(100%);
}
.thumbnail{
  padding:0;
  margin: 0;
  overflow: hidden !important;
}
.thumbnail-shadow{
  background-color: #03033c;
  opacity: 0.6;
  z-index: 200;
  position: absolute;
  width: 100%;
  height: 100%;
}
.thumbnail-img{
  width: 100%;
  /*filter: grayscale(100%);*/
  /*filter:hue-rotate(90deg);*/
}
.categories{
  padding: 0;
  overflow-y: hidden !important;
}
.category-description{
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 90%;
  right: 5%;
  z-index: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.category-name{
  font-size: 30px;
  text-align: center;
  font-weight: bolder;
  font-family: 'Vazir';
}
.tajhiz{
  color: orange;
}
.plast{
  color: blue;
}
.building{
  color:red;
}
.b-black{
  background-color: black;
}

.o-hidden{
  overflow: hidden;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.taj-enter,
.taj-leave-to {
  transition: all .5s ease-in;
  visibility: hidden;
  opacity: 0;
}
.taj-enter-to,
.taj-leave{
  transition: all 1s ease-in;
  visibility: visible;
  opacity: 1;
}
.slider-navigation{
  position: absolute;
  display: inline;
  color: white;
  bottom :9em;
  left : 48%;
  width:4%;
  z-index: 1500;
}
.slider-nav::marker{
  content: '';
  display: none;
}
div.slider-nav-img{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.4;
  margin:2px;
}
div.active{
  opacity: 1;
}
.cls-1,.cls-2,.cls-3,.cls-4,.cls-1{fill:none;stroke-miterlimit:10;}
.cls-2{stroke:#f9b729;fill-rule:evenodd;}
.cls-3{stroke:#e44135;}
.cls-4,.cls-1{stroke:#0c7da6;}
</style>
