<template>
    <div class="products">
        <box :border="border">
            <h3 slot="title">{{ $t('menu.products') }}</h3>
            <div class="main-products mx-auto col-10 mt-4 pt-4" slot="body">
              <button type="button" class="slick-prev" @click="slickPrev()"><img :src="'/img/'+this.slickIcon" class="icon prev-icon" /></button>
                <slick :options="options" ref="slick">
                    <router-link v-for="product in products[0].childsProduct" :key="product.id" :to="{ name:'Product', params:{language: $i18n.locale, category: $route.params.category,product:product.title}}">
                      <div class="product">
                        <div class="image-wrapper">
                          <img :src="'/img/'+product.pic" @error="$event.target.src='/img/default.png'" :alt="product.data.name" class="slick-image">
                          <div class="product-title">
                            <h3>{{ product.data.name }}</h3>
                          </div>
                        </div>
                      </div>
                    </router-link>
                </slick>
                <button type="button" class="slick-next" @click="slickNext()"><img :src="'/img/'+this.slickIcon" class="icon next-icon" /></button>
            </div>
        </box>
    </div>
</template>

<script>
import Box from './Box.vue'
import Slick from 'vue-slick';
import '@/../node_modules/slick-carousel/slick/slick.css';
import httpClient from '@/services/http.service';
    export default {
    components: { Box,Slick },
        name:'Products',
        props:{
            border:String,
            category:String,
        },
        data:()=>{
            return{
                options:{
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll:3,
                    rtl:true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    prevArrow:false,
                    nextArrow:false,
                },
                slickIcon:'17.png',
                products:Array,
                error : null,
            }
        },
        methods: {
          slickPrev() {
            this.$refs.slick.prev();
          },
          slickNext() {
            this.$refs.slick.next();
          }
        },
        async mounted() {
          //do something after mounting vue instance
          try {
            const {status,data} = await httpClient.get(`${this.$route.params.language}/categories`,{params:{title:this.category,expand:'childsProduct'}});
            this.products = data;
          } catch (e) {
            this.error = 'Error in Sending Data To Server';
          }
          if (this.category == 'tajhiz') {
            this.slickIcon = '16.png';
          }
        }

    }
</script>

<style lang="scss">
.slick-prev{
  position: absolute;
  //border-radius: 50%;
  border:none;
  background: transparent;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  top:50%;
  left: -50px;
}
.slick-next{
    position: absolute;
    //border-radius: 50%;
    border:none;
    background: transparent;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    top:50%;
    right: -50px;
    transform: rotate(180deg);
    //content: url('../../public/img/17.png');
}
.slick-image{
  padding: 0;
  width: 100%;
  margin-top: 5%;
  max-height: 300px;
}
.slick-next:active,.slick-prev:active{
  text-decoration: none;
  border: none;
}
.icon{
  width: 100%;
}
.prev-icon{
  transform: rotate() 1turn;
}
.product{
  background: transparent;
  margin-bottom:10px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.product-title{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.product-title:hover{
  color:rgba(40, 40, 110, 0.774);
}

.image-wrapper{
  width: 100%;
  overflow: hidden;
}
.product-image{
  display: block;
  width: 100%;
  height: auto;
  
}
a:hover{
  text-decoration: none;
}
.product-title h3{
  font-weight: bolder;
  font-size: 20px;
}
</style>
