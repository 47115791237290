<template>
  <div class='categories'>
    <vue-menu v-if="category != 'building'"></vue-menu>
    <menu-building v-else></menu-building>
    <full-page>
      <div slot="sections">
        <section class="fullpage-center" ref="sections">
          <vslider></vslider>
        </section>
        <section class="fullpage-center" ref="sections">
          <about :border="border" :bn="bn">
            <template slot="text">
              <div ref="about"></div>
            </template>
          </about>
        </section>
        <section class="fullpage-center" ref="sections">
          <products :border="border" class="w-100" :category = "category" v-if="category != 'building'"></products>
          <projects class="w-100" v-else></projects>
        </section>
        <section class="fullpage-center" ref="sections" v-if="category != 'building'">
          <customers :border="border" :category = "category"></customers>
        </section>
        <section class="fullpage-center" ref="sections">
          <Footer />
        </section>
      </div>
    </full-page>
  </div>
</template>

<script>
import Products from '../components/Products.vue'
import Customers from '../components/Customers.vue'
import About from '../components/About.vue'
import FullPage from '../components/FullPage'
import Menu from '../components/Menu';
import Footer from '../components/Footer2'
import Slider from '../components/Slider'
import httpClient from '../services/http.service';
import MenuBuilding from  '../components/MenuBuilding.vue';
    export default {
        name:'Categories',
        props:{
            menu:Boolean
        },
       data:function(){
           return{
               category : this.$route.params.category,
               border:null,
               bn:null,
               txt:null,
           }
       },
       methods: {
         async sincData() {
           try {
             const{status,data} = await httpClient.get(`${this.$route.params.language}/categories`,{params:{title:this.category}});
             if (status == 200) {
               this.$refs.about.innerHTML = '<p class="text">'+data[0].data.about+'</p>';
               this.txt = data[0].data.about;
             }
           } catch (e) {
             console.log(e);
           }
         }
       },
       mounted(){
           if(this.$route.params.category == 'plast'){
               this.border = 'border-plast';
               this.bn = 'bnb';
           }else if(this.$route.params.category == 'tajhiz'){
               this.border = 'border-tajhiz';
               this.bn = 'bnb';
           }else if(this.$route.params.category == 'building'){
             this.border = 'border-building';
             this.bn = 'bnb';
           }
           this.sincData();
       },
       components:{
           About, Customers,
            'vue-menu': Menu, Products,Footer,FullPage,
            'vslider': Slider,
            MenuBuilding,
       }
    }
</script>

<style lang="scss" scoped>

</style>
